<!-- 底部 -->
<template>
  <div class="footer">
    <ul>
      <li class="row1">
        <div>
          <img src="./../assets/img/pole labs.svg" alt="" />
          <span>Designed by Pole Labs</span>
        </div>
        <span class="iconList">Community & Partnerships</span>
      </li>
      <li class="row2">
        <div>
          <img :src="require('@/assets/img/telegram.svg')" @click="handleClick('https://t.me/revolutionNFT')" />
          <img :src="require('@/assets/img/social02.svg')" @click="handleClick('https://twitter.com/NFTRevo')" />
          <img :src="require('@/assets/img/reddit.svg')" @click="handleClick('https://www.reddit.com/r/RevoNFT/')" />
          <img :src="require('@/assets/img/youtube.svg')" @click="handleClick('https://www.youtube.com/channel/UCAVilc5UTUNkVAF3gfeqVIw')" />
          <img :src="require('@/assets/img/social04.svg')" @click="handleClick('https://revonft.gitbook.io/')" />
          <img :src="require('@/assets/img/discord.png')" @click="handleClick('https://discord.gg/Ra35WdAKBs')" />
        </div>
        <div class="iconList">
          <div >
            <img :src="require('@/assets/icon/icon1.svg')" @click="handleClick('https://www.circle.com/en/usdc')" />
            <img :src="require('@/assets/icon/icon2.svg')" @click="handleClick('https://tether.to/')" />
            <img :src="require('@/assets/icon/icon3.svg')" @click="handleClick('https://axieinfinity.com/')" />
            <img :src="require('@/assets/icon/icon4.svg')" @click="handleClick('https://decentraland.org/')" />
            <img :src="require('@/assets/icon/icon5.svg')" @click="handleClick('https://compound.finance/')" />
            <img :src="require('@/assets/icon/icon6.svg')" @click="handleClick('https://www.sushi.com/')" />
            <img :src="require('@/assets/icon/icon7.svg')" @click="handleClick('https://curve.fi/')" />
            <img :src="require('@/assets/icon/icon8.svg')" @click="handleClick('https://ethereum.org')" />
            <img :src="require('@/assets/icon/icon9.svg')" @click="handleClick('https://ipfs.io/')" />
            <img :src="require('@/assets/icon/icon10.svg')" @click="handleClick('https://filecoin.io/')" />
            <img :src="require('@/assets/icon/icon11.svg')" @click="handleClick('https://aave.com/')" />
            <img :src="require('@/assets/icon/icon12.svg')" @click="handleClick('https://metamask.io/')" />
            <img :src="require('@/assets/icon/icon13.svg')" @click="handleClick('https://chain.link/')" />
            <img :src="require('@/assets/icon/icon14.svg')" @click="handleClick('https://zksync.io/')" />
          </div>
          <div class="icon">
            <img :src="require('@/assets/icon/icon15.svg')" @click="handleClick('https://arbitrum.io/rollup')" />
            <img :src="require('@/assets/icon/icon16.svg')" @click="handleClick('https://www.optimism.io/')"/>
            <img :src="require('@/assets/icon/icon17.svg')" @click="handleClick('https://polygon.technology/')" />
            <img :src="require('@/assets/icon/icon18.svg')" @click="handleClick('https://zerion.io/')" />
            <img :src="require('@/assets/icon/icon19.svg')" @click="handleClick('https://tornado.dev/')" />
            <img :src="require('@/assets/icon/icon20.svg')" @click="handleClick('https://ens.domains/')" />
            <img :src="require('@/assets/icon/icon21.svg')" @click="handleClick('https://uniswap.org/')" />
            <img :src="require('@/assets/icon/icon22.svg')" @click="handleClick('https://superrare.com/')" />
            <img :src="require('@/assets/icon/icon23.svg')" @click="handleClick('https://rarible.com/')" />
            <img :src="require('@/assets/icon/icon24.svg')" @click="handleClick('https://opensea.io/')" />
            <img :src="require('@/assets/icon/icon25.svg')" @click="handleClick('https://www.nbatopshot.com/')" />
            <img :src="require('@/assets/icon/icon26.svg')" @click="handleClick('https://www.cryptokitties.co/')" />
            <img :src="require('@/assets/icon/icon27.svg')" @click="handleClick('https://makerdao.com/en/governance')" />
            <img :src="require('@/assets/icon/icon28.svg')" @click="handleClick('https://www.binance.com/')" />
          </div>
        </div>
  
        <!-- <img src="./../assets/img/computecoin.svg" alt="" /> -->
      </li>
      <li class="Copyright">Copyright 2021 Revolution,All rights Reserved</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    handleClick(src){
        if(src){
          window.open(src);
        }
    }
  },
};
</script>
<style lang='less' scoped>
.footer {
  background: #0c1a4b;
  color: rgb(255, 255, 255);
  font-size:14px;
  padding: 10px 0;
  ul {
    li {
      margin:10px auto 0;
      // margin: 0 80px;
      width: 1000px;
      line-height: 40px;
      display: flex;
      align-items: center;
      justify-content:flex-start;
      div {
        display: flex;
        align-items: center;
      }
      &.row1 {
        img {
          width: 110px;
          margin-right: 15px;
        }
        & > span {
          color: #fff;
          // font-weight:600;
        }
        .iconList{
          margin-left: 40px;
        }
      }
      &.row2 {
        max-height:400px;
        & > div {
          img {
            width: 24px;
            height: 24px;
            margin-right: 24px;
            cursor: pointer;
          }
        }
        .iconList{
          display: flex;
          flex-direction: column; 
          margin-left: 30px;
          .icon{
            padding: 10px 0;
          }
        }
      }
      &.Copyright {
        justify-content: center;
        margin-top: 15px;
        width: 100%;
        line-height: 40px;
        border-top: 1px solid #273a79;
      }
    }
  }
}
</style>